import { useState, useEffect } from 'react'

const CELL_SIZE = 32

export default function DiscoBackground() {
  const [dimensions, setDimensions] = useState({
    columns: 0,
    rows: 0,
  })
  const [activeColor, setActiveColor] = useState<
    (typeof colors)[0] | undefined
  >(undefined)

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        columns: Math.ceil(window.innerWidth / CELL_SIZE),
        rows:
          Math.ceil((window.innerHeight - 32) / CELL_SIZE) +
          (Math.ceil((window.innerHeight - 32) / CELL_SIZE) % 2),
      })
    }
    updateDimensions()

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return (
    <div
      className="absolute top-0 left-0 w-full h-full grid overflow-hidden border-b border-border"
      style={{
        gridTemplateColumns: `repeat(${dimensions.columns}, ${CELL_SIZE}px)`,
        gridTemplateRows: `repeat(${dimensions.rows}, ${CELL_SIZE}px)`,
      }}
    >
      {Array.from({ length: dimensions.columns * dimensions.rows }).map(
        (_, i) => (
          <DiscoCell key={i} activeColor={activeColor} />
        )
      )}
      <div className="hidden sm:flex absolute bottom-0 right-0 gap-2 p-2 bg-background border-l border-t border-border">
        {colors.map((color) => (
          <div
            key={color.name}
            className={`w-3 h-3 cursor-pointer border-2 opacity-50 hover:opacity-100 ${
              color.classes
            } ${
              activeColor === color
                ? 'border-[6px] opacity-100'
                : 'border-border'
            }`}
            onClick={() =>
              setActiveColor(activeColor === color ? undefined : color)
            }
          />
        ))}
      </div>
    </div>
  )
}

const DiscoCell = ({ activeColor }: { activeColor?: (typeof colors)[0] }) => {
  const [color, setColor] = useState(activeColor)
  const [active, setActive] = useState(false)
  const [tempActive, setTempActive] = useState(false)

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)]
  }

  function hover(e: React.MouseEvent<HTMLDivElement>) {
    if (!active) setColor(activeColor || getRandomColor())

    setTempActive(true)

    setTimeout(() => {
      setTempActive(false)
    }, 1000)

    if (e.buttons === 1) {
      if (active && activeColor && color !== activeColor) {
        setColor(activeColor)
      } else {
        setActive((prev) => !prev)
      }
    }
  }

  function activate(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault()
    setActive((prev) => !prev)
  }

  return (
    <div
      className={`
        border
        box-border 
        cursor-pointer
        ${
          tempActive || active
            ? color?.classes
            : 'bg-background border-border/60'
        }
      `}
      onMouseEnter={hover}
      onMouseDown={activate}
      style={{
        width: CELL_SIZE,
        height: CELL_SIZE,
        borderWidth: active ? '2px' : '0.5px',
      }}
    />
  )
}

const colors = [
  {
    name: 'warning',
    classes: 'bg-warning-tint border-warning',
  },
  {
    name: 'error',
    classes: 'bg-error-tint border-error',
  },
  {
    name: 'success',
    classes: 'bg-success-tint border-success',
  },
  {
    name: 'info',
    classes: 'bg-info-tint border-info',
  },
]
