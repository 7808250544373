import { useEffect, useRef, useState } from 'react'
import Prism from 'prismjs'
import 'prism-themes/themes/prism-vsc-dark-plus.css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-bash'
import { LuCheck, LuCopy } from 'react-icons/lu'

interface CodeSnippet {
  title: string
  language: string
  content: string
}

export const CodeBlock = ({ snippets }: { snippets: CodeSnippet[] }) => {
  const [activeTab, setActiveTab] = useState(snippets[0])
  const [copied, setCopied] = useState(false)
  const codeRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current)
    }
  }, [activeTab])

  const handleCopy = async () => {
    await navigator.clipboard.writeText(activeTab.content)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  if (snippets.length === 0) return null

  return (
    <div className="h-full flex flex-col rounded border border-code-border bg-code-tabs overflow-hidden">
      <div className="flex justify-between items-center">
        <div className="flex overflow-x-auto">
          {snippets.map((snippet, index) => {
            const isActive = activeTab.title === snippet.title
            const activeClass = 'text-text bg-code-bg'
            const inactiveClass = 'text-text2'

            return (
              <button
                key={index}
                className={`text-xs px-4 py-3 box-border hover:text-text ${
                  isActive ? activeClass : inactiveClass
                }`}
                onClick={() => setActiveTab(snippet)}
              >
                {snippet.title}
              </button>
            )
          })}
        </div>
        <button
          onClick={handleCopy}
          className="p-2 mr-1 h-8 w-8 hover:bg-input-active rounded-lg transition-colors"
        >
          {copied ? (
            <LuCheck className="w-4 h-4 text-success" />
          ) : (
            <LuCopy className="w-4 h-4 text-text2" />
          )}
        </button>
      </div>
      <div className="flex-grow overflow-x-auto relative">
        {snippets.map((snippet) => (
          <pre
            key={snippet.title}
            className={`px-5 py-4 bg-code-bg ${
              activeTab.title === snippet.title ? 'block' : 'hidden'
            }`}
          >
            <code
              ref={activeTab.title === snippet.title ? codeRef : undefined}
              className={`language-${snippet.language}`}
            >
              {snippet.content}
            </code>
          </pre>
        ))}
      </div>
    </div>
  )
}
