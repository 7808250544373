import { ButtonHTMLAttributes, FC, useState, useEffect } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'default' | 'outline'
}

export const Button: FC<ButtonProps> = ({
  className = '',
  theme = 'default',
  ...props
}) => {
  const themeClasses = {
    default: 'bg-cta-base text-cta-text hover:bg-cta-hover',
    outline:
      'bg-background border border-panel-border text-text-1 hover:bg-panel-background',
  }

  return (
    <button
      className={`px-4 py-2 ${themeClasses[theme]} transition-colors rounded-md ${className}`}
      {...props}
    />
  )
}

export const ArcadeButton: FC<ButtonProps> = ({
  className = '',
  theme = 'default',
  ...props
}) => {
  const [colorIndex, setColorIndex] = useState(0)
  const [isHovering, setIsHovering] = useState(false)
  const colors = [
    { bg: 'bg-warning-tint', border: 'border-warning', text: 'text-warning' },
    { bg: 'bg-success-tint', border: 'border-success', text: 'text-success' },
    { bg: 'bg-error-tint', border: 'border-error', text: 'text-error' },
    { bg: 'bg-info-tint', border: 'border-info', text: 'text-info' },
  ]

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (isHovering) {
      setColorIndex(1)
      interval = setInterval(() => {
        setColorIndex((prev) => (prev + 1) % colors.length)
      }, 200)
    } else {
      setColorIndex(0)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isHovering])

  const currentColor = colors[colorIndex]

  return (
    <button
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={`
        px-4 py-2 
        border
        text-sm
        font-space
        ${currentColor.bg}
        ${isHovering ? currentColor.border : 'border-warning-tint'}
        ${currentColor.text}
        ${className}
      `}
      {...props}
    />
  )
}
