export default function TetrisSquare() {
  const colors = {
    error: 'bg-error-tint border-error',
    warning: 'bg-warning-tint border-warning',
    info: 'bg-info-tint border-info',
    success: 'bg-success-tint border-success',
  } as const

  const gridLayout = [
    [1, 1, 2, 3],
    [1, 2, 2, 3],
    [1, 2, 3, 3],
    [4, 4, 4, 4],
  ]

  const pieceColors = {
    1: colors.warning,
    2: colors.success,
    3: colors.error,
    4: colors.info,
  } as const

  const getBorderClasses = (row: number, col: number): string => {
    const currentPiece = gridLayout[row][col]
    const hasTopPiece = row > 0 && gridLayout[row - 1][col] === currentPiece
    const hasBottomPiece = row < 3 && gridLayout[row + 1][col] === currentPiece
    const hasLeftPiece = col > 0 && gridLayout[row][col - 1] === currentPiece
    const hasRightPiece = col < 3 && gridLayout[row][col + 1] === currentPiece

    return [
      !hasTopPiece && 'border-t',
      !hasBottomPiece && 'border-b',
      !hasLeftPiece && 'border-l',
      !hasRightPiece && 'border-r',
    ]
      .filter(Boolean)
      .join(' ')
  }

  return (
    <div className="absolute top-0 left-0 w-full h-full grid grid-cols-4 grid-rows-4">
      {gridLayout.map((row, rowIndex) =>
        row.map((piece, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            className={`w-full h-full 
              ${pieceColors[piece as keyof typeof pieceColors]} 
              ${getBorderClasses(rowIndex, colIndex)}
            `}
          />
        ))
      )}
    </div>
  )
}
