const ListBlock = ({
  children,
  bottom = false,
}: {
  children: React.ReactNode
  bottom?: boolean
}) => {
  return (
    <ul className={`list-disc list-inside space-y-2  ${bottom ? '' : 'mb-8'}`}>
      {children}
    </ul>
  )
}

export default ListBlock
