import React from 'react'
import { logMessages } from './logsMessages'

export default function LogFeedCard() {
  return (
    <div className="relative w-full max-w-[800px] bg-background2 border border-border">
      <div className="p-8">
        <h2 className="mb-3 text-2xl font-nippo font-extralight select-none">
          Unified Log Management
        </h2>
        <p className="max-w-[600px] text-base text-text2 font-space font-light select-none">
          See logs from all your services, neatly organized by environment.{' '}
          <br /> Full visibility, zero chaos.
        </p>
      </div>
      <div className="w-full h-[400px] flex flex-col border-t border-border overflow-hidden sm:overflow-y-scroll sm:overflow-x-scroll">
        <div className="sticky top-0 flex flex-row bg-background2 border-b border-border">
          <TableCell className="w-[160px]">timestamp</TableCell>
          <TableCell className="w-[80px]">service</TableCell>
          <TableCell className="flex-grow">body</TableCell>
        </div>
        {logMessages.map((log, index) => (
          <div
            key={index}
            className="flex flex-row border-b border-border last:border-b-0"
          >
            <TableCell className="w-[160px]">{log.timestamp}</TableCell>
            <TableCell className="w-[80px]">{log.service}</TableCell>
            <TableCell className="w-[200px] flex-grow">{log.body}</TableCell>
          </div>
        ))}
      </div>
    </div>
  )
}

interface TableCellProps {
  children: React.ReactNode
  className?: string
}

function TableCell({ children, className }: TableCellProps) {
  return (
    <div
      className={`px-3 py-1 flex-shrink-0 font-jetbrains font-light text-xs text-text2 border-r border-border last:border-r-0 truncate ${className}`}
    >
      {children}
    </div>
  )
}
