import Footer from '../../library/Footer/Footer'
import Navbar from '../../library/Navbar/Navbar'

export default function Pricing() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main
        className="min-h-screen px-4 py-24 sm:py-32 flex justify-center"
        style={{
          backgroundImage: "url('/pacmanbg.png')",
          backgroundSize: '50%',
        }}
      >
        <div className="w-full max-w-[600px] p-4 sm:p-8 flex flex-col bg-background2 border border-border self-center">
          <h1 className="text-2xl mb-6 font-space text-text">
            Pricing <span className="text-text2">(coming soon)</span>
          </h1>
          <div className="w-full h-[1px] mb-6 bg-border" />
          <p className="mb-2 font-sans font-bold text-sm text-text">TLDR</p>
          <p className="mb-8 font-sans font-light text-sm text-text2">
            Vigilant is free right now. Later it will be usage-based, with a
            free tier.
          </p>
          <p className="mb-2 font-sans font-bold text-sm text-text">
            Full Story
          </p>
          <p className="mb-6 font-sans font-light text-sm text-text2">
            Our plan is to do usage-based pricing, based on volume and
            retention. This is mandatory for observability tools that process
            huge amount of data. But building it would take time. We don't
            charge because we haven't gotten to it yet.
          </p>
          <p className="mb-6 font-sans font-light text-sm text-text2">
            We also plan to have a significant free tier. Startups with low
            volume will be able to use Vigilant for free. We only have a few
            users who would be above the free tier anyway, so we haven't been in
            a rush to build pricing. And we have a lot of other requests to
            build right now.
          </p>
          <p className="mb-6 font-sans font-light text-sm text-text2">
            But we want to be clear, this will be a paid product. It'll be
            cheaper than the gratuitous incumbents, but if you start sending us
            a terabyte every day, expect a bill soon after.
          </p>
          <p className="mb-6 font-sans font-light text-sm text-text2">
            We'll contact all current users before anything changes.
          </p>
          <div className="flex gap-4 items-center">
            <img
              src="/ben.png"
              alt="Ben Shumaker Profile Picture"
              className="w-8 h-8 rounded-full"
            />
            <div className="flex flex-col">
              <p className="text-text text-sm font-light">Ben Shumaker</p>
              <p className="text-text2 text-sm font-light">cofounder</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}
