const INITIAL_TIMESTAMP = 'Jan 24 09:32:18 AM'

export const logs = [
  {
    service: 'client',
    body: '[event] Login form submitted',
    delay: 0,
  },
  {
    service: 'api',
    body: '[POST] /auth/login pass:"HUNTER22" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[fail] Invalid credentials (attempt 1/5)',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Login form submitted... again',
    delay: 2000,
  },
  {
    service: 'api',
    body: '[POST] /auth/login pass:"HUNTER22" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[fail] Nice try, but still wrong (2/5)',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Checking Google password manager',
    delay: 2000,
  },
  {
    service: 'client',
    body: "[event] They didn't find it",
    delay: 5000,
  },
  {
    service: 'client',
    body: '[event] Login form submitted... aggressively',
    delay: 4000,
  },
  {
    service: 'api',
    body: '[POST] /auth/login pass:"HUNTER22" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[fail] Bro... you good? (3/5)',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Click forgot password link',
    delay: 2000,
  },
  {
    service: 'api',
    body: '[POST] /auth/reset-password-email',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[info] Password reset email sent',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] New password submitted',
    delay: 2000,
  },
  {
    service: 'api',
    body: '[POST] /auth/reset-password pass:"HUNTER22" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: "[fail] Your new password can't be the same as your old one",
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Reset password submitted',
    delay: 500,
  },
  {
    service: 'api',
    body: '[POST] /auth/reset-password pass:"FUCK OFF" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[fail] Password cannot contain a space',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Reset password submitted',
    delay: 500,
  },
  {
    service: 'api',
    body: '[POST] /auth/reset-password pass:"FUCK OFF" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[fail] lmao',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Reset password submitted',
    delay: 500,
  },
  {
    service: 'api',
    body: '[POST] /auth/reset-password pass:"I HATE YOU" - rejected',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[fail] We will never let you log in',
    delay: 100,
  },
  {
    service: 'client',
    body: '[event] Taking deep breaths',
    delay: 3000,
  },
  {
    service: 'client',
    body: '[event] Turns off caps lock',
    delay: 2000,
  },
  {
    service: 'client',
    body: '[event] Login form submitted',
    delay: 1000,
  },
  {
    service: 'api',
    body: '[POST] /auth/login pass:"Hunter22" - accepted',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[info] Login success',
    delay: 100,
  },
  {
    service: 'auth',
    body: '[info] Hi Hunter, welcome back to club penguin!',
    delay: 100,
  },
]

export const logMessages = logs.map((log, index, array) => {
  const totalDelay = array
    .slice(0, index + 1)
    .reduce((sum, msg) => sum + msg.delay, 0)

  return {
    ...log,
    timestamp:
      index === 0
        ? INITIAL_TIMESTAMP
        : addMillisecondsToTimestamp(INITIAL_TIMESTAMP, totalDelay),
  }
})

function addMillisecondsToTimestamp(
  timestamp: string,
  milliseconds: number
): string {
  const [month, day, timeStr, period] = timestamp.split(' ')
  const [hours, minutes, seconds] = timeStr.split(':').map(Number)

  const date = new Date(
    2024,
    getMonthIndex(month),
    parseInt(day),
    period === 'PM' ? (hours % 12) + 12 : hours % 12,
    minutes,
    seconds
  )

  date.setMilliseconds(date.getMilliseconds() + milliseconds)

  return `${date.toLocaleString('en-US', { month: 'short' })} ${date
    .getDate()
    .toString()
    .padStart(2, '0')} ${date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  })}`
}

function getMonthIndex(month: string): number {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return months.indexOf(month)
}
