import DividerDots from './DividerDots/DividerDots'
import LogFeedCard from './Cards/LogFeedCard/LogFeedCard'
import SearchCard from './Cards/SearchCard'
import CodeCard from './Cards/CodeCard/CodeCard'
import AlertCard from './Cards/AlertCard'

export default function FeaturesSection() {
  return (
    <div
      className="relative w-full px-4 py-24 flex flex-col items-center gap-16 bg-repeat overflow-hidden border-b border-border"
      style={{
        backgroundImage: "url('/pacmanbg.png')",
        backgroundSize: '50%',
      }}
    >
      <DividerDots color="warning" />
      <LogFeedCard />
      <DividerDots color="info" />
      <div className="max-w-[800px] flex flex-col sm:flex-row gap-16">
        <SearchCard />
        <AlertCard />
      </div>
      <DividerDots color="error" />
      <CodeCard />
      <DividerDots color="success" />
    </div>
  )
}
