import { useEffect, useRef, useState } from 'react'

interface SpinSquareProps {
  children: React.ReactNode
  className?: string
}

export default function SpinSquare({
  children,
  className = '',
}: SpinSquareProps) {
  const cubeRef = useRef<HTMLDivElement>(null)
  const [rotation, setRotation] = useState(0)
  const [isRotating, setIsRotating] = useState(false)
  const [rotationOffset, setRotationOffset] = useState(0)
  const [center, setCenter] = useState({ x: 0, y: 0 })
  const [velocity, setVelocity] = useState(0)
  const lastRotationRef = useRef(0)
  const lastTimeRef = useRef(Date.now())
  const animationFrameRef = useRef<number>()
  const [spinCount, setSpinCount] = useState(0)
  const [hasSpun, setHasSpun] = useState(false)
  const initialRotationRef = useRef(0)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsRotating(true)
    setSpinCount(0)
    setHasSpun(true)
    initialRotationRef.current = rotation
    if (!cubeRef.current) return
    const cubeRect = cubeRef.current.getBoundingClientRect()
    const center = {
      x: cubeRect.left + cubeRect.width / 2,
      y: cubeRect.top + cubeRect.height / 2,
    }
    setCenter(center)
    const angleFromCenter = Math.atan2(
      e.clientY - center.y,
      e.clientX - center.x
    )
    const angleFromCenterDegrees = (angleFromCenter * 180) / Math.PI
    setRotationOffset(angleFromCenterDegrees - rotation)
  }

  useEffect(() => {
    const handleMouseUp = () => {
      setIsRotating(false)
    }
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isRotating) return
      const angleFromCenter = Math.atan2(
        e.clientY - center.y,
        e.clientX - center.x
      )
      const angleFromCenterDegrees = (angleFromCenter * 180) / Math.PI
      const newRotation = angleFromCenterDegrees - rotationOffset

      const currentTime = performance.now()
      const deltaTime = currentTime - lastTimeRef.current
      const deltaRotation = newRotation - lastRotationRef.current
      const newVelocity = (deltaRotation / deltaTime) * 16.67

      setVelocity(newVelocity)
      setRotation(newRotation)

      lastRotationRef.current = newRotation
      lastTimeRef.current = currentTime
    }
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isRotating, center, rotationOffset])

  useEffect(() => {
    let lastTimestamp = performance.now()
    let lastRotation = rotation

    const animate = (timestamp: number) => {
      const deltaTime = timestamp - lastTimestamp
      const frameVelocity = (velocity * deltaTime) / 16.67

      setRotation((prev) => {
        const newRotation = prev + frameVelocity
        const totalRotation = Math.abs(newRotation - initialRotationRef.current)
        const fullSpins = Math.floor(totalRotation / 360)
        setSpinCount(fullSpins)
        return newRotation
      })
      setVelocity((prev) => prev * 0.97)

      lastTimestamp = timestamp
      lastRotation = rotation
      animationFrameRef.current = requestAnimationFrame(animate)
    }

    if (!isRotating && Math.abs(velocity) > 0.01) {
      animationFrameRef.current = requestAnimationFrame(animate)
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [isRotating, velocity])

  const getSpinColorClass = (spins: number): string => {
    if (spins >= 10) return 'text-success'
    if (spins >= 5) return 'text-info'
    if (spins >= 2) return 'text-warning'
    return 'text-error'
  }

  const getSpinBackgroundColorClass = (spins: number): string => {
    if (spins >= 10) return 'bg-success-tint border-success'
    if (spins >= 5) return 'bg-info-tint border-info'
    if (spins >= 2) return 'bg-warning-tint border-warning'
    return 'bg-popup-bg border-popup-border'
  }

  return (
    <>
      <div
        ref={cubeRef}
        className={`relative cursor-grab ${
          isRotating ? 'cursor-grabbing' : ''
        } ${className}`}
        style={{ transform: `rotateZ(${rotation}deg)` }}
        onMouseDown={handleMouseDown}
      >
        {children}
      </div>
      {hasSpun && (
        <div
          className={`absolute hidden sm:block w-[64px] h-[32px] right-[32px] top-[50%] border ${getSpinBackgroundColorClass(
            spinCount
          )}`}
        >
          <p
            className={`w-full h-full flex items-center justify-center text-base font-space font-light select-none ${getSpinColorClass(
              spinCount
            )}`}
          >
            {spinCount}
          </p>
        </div>
      )}
    </>
  )
}
