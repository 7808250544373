import { useState } from 'react'

export default function SearchCard() {
  const [pillOrder, setPillOrder] = useState([0, 1, 2, 3])

  const handlePillClick = (index: number) => {
    setPillOrder((current) => {
      const isLast = current.indexOf(index) === current.length - 1
      const newOrder = current.filter((i) => i !== index)
      return isLast ? [index, ...newOrder] : [...newOrder, index]
    })
  }

  const pills = [
    { color: 'error', text: 'level == error' },
    { color: 'info', text: 'service == backend' },
    { color: 'warning', text: 'time == June 12, 12:34:03' },
    { color: 'success', text: 'email == nbushnell@atari.com' },
  ] as const

  return (
    <div className="relative w-full bg-background2 border border-border">
      <div className="p-8">
        <h2 className="mb-3 text-2xl font-nippo font-extralight select-none">
          Intuitive Search
        </h2>
        <p className="max-w-[600px] text-base text-text2 font-space font-light select-none">
          Quickly find logs by filtering on relevant attributes.
        </p>
      </div>
      <div className="relative w-full h-[288px] px-8 pb-8">
        {pills.map((pill, index) => (
          <div
            key={pill.text}
            className="absolute top-0 left-8 right-8"
            style={{
              transform: `translateY(${pillOrder.indexOf(index) * 64}px)`,
              transition: 'transform 0.08s ease-in-out',
              cursor: 'pointer',
            }}
            onClick={() => handlePillClick(index)}
          >
            <SearchPill color={pill.color}>{pill.text}</SearchPill>
          </div>
        ))}
      </div>
    </div>
  )
}

interface SearchPillProps {
  children: React.ReactNode
  color: 'error' | 'info' | 'success' | 'warning'
}

function SearchPill({ children, color }: SearchPillProps) {
  return (
    <div className={`p-4 py-4 rounded border ${colors[color].container}`}>
      <p
        className={`text-xs font-jetbrains font-light select-none ${colors[color].text}`}
      >
        {children}
      </p>
    </div>
  )
}

const colors = {
  error: {
    container: 'bg-error-tint border-error-tint',
    text: 'text-error',
  },
  info: {
    container: 'bg-info-tint border-info-tint',
    text: 'text-info',
  },
  success: {
    container: 'bg-success-tint border-success-tint',
    text: 'text-success',
  },
  warning: {
    container: 'bg-warning-tint border-warning-tint',
    text: 'text-warning',
  },
}
