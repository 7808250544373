interface SectionProps {
  date: string
  changes: React.ReactNode[]
  color: 'info' | 'success' | 'error' | 'warning'
}

const Section = ({ date, changes, color }: SectionProps) => {
  const styles = colors[color]

  return (
    <div className="relative flex md:flex-row md:gap-8 gap-4">
      <div className="hidden md:block w-[150px] h-fit sticky top-[100px] flex-shrink-0">
        <DatePill date={date} color={color} />
      </div>
      <div className="relative flex-shrink-0">
        <div
          className={`sticky top-[111px] w-[8px] h-[8px] my-[11px] mx-1 ${styles.decoration}`}
        />
        <div
          className={`absolute top-[12px] left-[7.5px] w-[1px] h-full ${styles.decoration}`}
        />
      </div>

      <div className="leading-relaxed mb-8">
        <DatePill date={date} color={color} className="md:hidden" />
        {changes}
      </div>
    </div>
  )
}

function DatePill({
  date,
  color,
  className = '',
}: {
  date: string
  color: SectionColor
  className?: string
}) {
  return (
    <div
      className={`w-fit h-fit px-2 py-1 mb-4 rounded-full ${colors[color].bg} border ${colors[color].border} ${className}`}
    >
      <h2
        className={`text-sm ${colors[color].text} font-light whitespace-nowrap`}
      >
        {date}
      </h2>
    </div>
  )
}

export type SectionColor = 'error' | 'success' | 'info' | 'warning'
const colors = {
  error: {
    text: 'text-error',
    decoration: 'bg-error',
    bg: 'bg-error-tint',
    border: 'border-error',
  },
  success: {
    text: 'text-success',
    decoration: 'bg-success',
    bg: 'bg-success-tint',
    border: 'border-success',
  },
  info: {
    text: 'text-info',
    decoration: 'bg-info',
    bg: 'bg-info-tint',
    border: 'border-info',
  },
  warning: {
    text: 'text-warning',
    decoration: 'bg-warning',
    bg: 'bg-warning-tint',
    border: 'border-warning',
  },
}

export default Section
