import Navbar from '../../library/Navbar/Navbar'
import Footer from '../../library/Footer/Footer'
import HeadlineSection from './HeadlineSection/HeadlineSection'
import BenefitSection from './BenefitSection/BenefitSection'
import CallToActionSection from './CallToActionSection/CallToActionSection'
import FeaturesSection from './FeaturesSection/FeaturesSection'

export default function Home() {
  return (
    <div className="relative box-border w-full">
      <Navbar />
      <HeadlineSection />
      <FeaturesSection />
      <BenefitSection />
      <CallToActionSection />
      <Footer />
    </div>
  )
}
