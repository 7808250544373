import Footer from '../../library/Footer/Footer'
import Navbar from '../../library/Navbar/Navbar'
import Section, { SectionColor } from './Section/Section'
import ListBlock from './ListBlock/ListBlock'
import ListItem from './ListItem/ListItem'
import ListTitle from './ListTitle/ListTitle'
import LogsLink from './LogsLink/LogsLink'
import AlertsLink from './AlertsLink/AlertsLink'

const Changelog = () => {
  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundImage: "url('/pacmanbg.png')",
        backgroundSize: '70%',
      }}
    >
      <Navbar />
      <main className="flex-grow container mx-auto px-6 sm:px-16 max-w-4xl py-32">
        <h1 className="text-4xl font-bold mb-4 text-text1">Changelog</h1>
        <p className="text-text2 mb-8">Newest updates and improvements</p>
        {sections.map((section) => (
          <Section
            key={section.id}
            date={section.date}
            changes={section.changes}
            color={colors[section.id % colors.length]}
          />
        ))}
        <div className="w-[8px] h-[8px] md:ml-[186px] ml-1 my-2 bg-info" />
      </main>
      <Footer />
    </div>
  )
}

const colors: SectionColor[] = ['info', 'warning', 'success', 'error']

const sections = [
  {
    id: 2,
    date: 'January 17, 2025',
    changes: [
      <ListBlock>
        <ListTitle>New Features</ListTitle>
        <ListItem>
          <AlertsLink /> for notifying when certain logs are created
        </ListItem>
        <ListItem>
          Slack integration for sending alerts to your Slack channels
        </ListItem>
        <ListItem>Email integration for sending alerts to your email</ListItem>
        <ListItem>
          Querying on all visible columns in <LogsLink />
        </ListItem>
      </ListBlock>,
      <ListBlock>
        <ListTitle>UI Updates</ListTitle>
        <ListItem>
          Column rearranging and auto-sizing for <LogsLink />
        </ListItem>
        <ListItem>Better responsiveness for small screens</ListItem>
      </ListBlock>,
      <ListBlock>
        <ListTitle>Bug Fixes</ListTitle>
        <ListItem>
          Bugs with <LogsLink /> auto-scrolling
        </ListItem>
        <ListItem>UI flickering bugs when loading the dashboard</ListItem>
      </ListBlock>,
    ],
  },
  {
    id: 1,
    date: 'January 10, 2025',
    changes: [
      <ListBlock>
        <ListTitle>New Features</ListTitle>
        <ListItem>
          Better attribute filtering for <LogsLink />
        </ListItem>
        <ListItem>
          Semantic timerange searches for <LogsLink />
        </ListItem>
        <ListItem>
          Attributes counts to <LogsLink /> sidebar
        </ListItem>
        <ListItem>
          Search result highlighting for <LogsLink />
        </ListItem>
      </ListBlock>,
      <ListBlock>
        <ListTitle>UI Updates</ListTitle>
        <ListItem>New design system for the dashboard</ListItem>
      </ListBlock>,
    ],
  },
  {
    id: 0,
    date: 'January 03, 2025',
    changes: [
      <ListBlock>
        <ListTitle>New Features</ListTitle>
        <ListItem>
          Infinite scroll for <LogsLink />
        </ListItem>
      </ListBlock>,
      <ListBlock>
        <ListTitle>UI Updates</ListTitle>
        <ListItem>Rewrote entire dashboard UI to be more consistent</ListItem>
        <ListItem>Fixed minor UX issues</ListItem>
      </ListBlock>,
      <ListBlock bottom={true}>
        <ListTitle>SDK Updates</ListTitle>
        <ListItem>
          Async local storage attributes for the{' '}
          <a
            href="https://docs.vigilant.run/documentation/nodejs"
            className="text-info underline"
          >
            JavaScript SDK
          </a>
        </ListItem>
      </ListBlock>,
    ],
  },
]

export default Changelog
