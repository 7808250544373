import DiscoBackground from './DiscoBackground/DiscoBackground'
import HeadlineCard from './HeadlineCard/HeadlineCard'

const HeadlineSection: React.FC = () => {
  return (
    <div className="relative w-full h-[calc(100vh-96px)] p-4 flex justify-center items-center overflow-hidden">
      <HeadlineCard />
      <DiscoBackground />
    </div>
  )
}

export default HeadlineSection
