import TetrisSquare from './TetrisSquare/TetrisSquare'
import SpinSquare from './SpinSquare/SpinSquare'

export default function BenefitSection() {
  return (
    <div
      className="relative w-full py-[128px] flex justify-center items-center bg-repeat overflow-hidden border-b border-border"
      style={{
        backgroundImage: "url('/tetrisbg.png')",
        backgroundSize: '40%',
      }}
    >
      <SpinSquare className="w-[90vw] h-[90vw] sm:w-[70vw] sm:h-[70vw] max-w-[70vh] max-h-[70vh] bg-background2">
        <div className="relative z-20 w-full h-full grid grid-cols-4 grid-rows-4 pointer-events-none">
          <div
            className="p-4 sm:p-8 flex flex-col justify-center text-xl select-none"
            style={{
              gridArea: '1 / 1 / 2 / 3',
            }}
          >
            <h3 className="text-sm sm:text-xl font-nippo font-light text-warning mb-1">
              Easy Setup
            </h3>
            <p className="text-xs sm:text-base font-space font-light text-warning">
              It's 10 minutes and 10 lines of code.
            </p>
          </div>
          <div
            className="p-4 sm:p-8 flex flex-col justify-center select-none"
            style={{
              gridArea: '2 / 2 / 3 / 4',
            }}
          >
            <h3 className="text-sm sm:text-xl font-nippo font-light text-success mb-1">
              Free to try
            </h3>
            <p className="text-xs sm:text-base font-space font-light text-success">
              You don't even need a credit card.
            </p>
          </div>
          <div
            className="p-4 sm:p-8 flex flex-col justify-center select-none"
            style={{
              gridArea: '3 / 3 / 4 / 5',
            }}
          >
            <h3 className="text-sm sm:text-xl font-nippo font-light text-error mb-1">
              Self Serve
            </h3>
            <p className="text-xs sm:text-base font-space font-light text-error">
              No need to talk to a human.
            </p>
          </div>
          <div
            className="p-4 sm:p-8 flex justify-between gap-2 select-none"
            style={{
              gridArea: '4 / 1 / 5 / 5',
            }}
          >
            <div className="flex flex-col justify-center">
              <h3 className="text-sm sm:text-xl font-nippo font-light text-info mb-1">
                Risk Free
              </h3>
              <p className="text-xs sm:text-base font-space font-light text-info">
                This is the part where you try it.
              </p>
            </div>
            <a
              href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}
              className="text-xs sm:text-base bg-info-tint text-info px-2 py-1 border border-info-tint hover:border-info self-center flex-shrink-0 pointer-events-auto"
            >
              Sure, why not?
            </a>
          </div>
        </div>
        <TetrisSquare />
      </SpinSquare>
    </div>
  )
}
