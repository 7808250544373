import { useState } from 'react'
import { LuMenu, LuX } from 'react-icons/lu'

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className="fixed w-full h-[67px] top-0 left-0 right-0 flex items-center justify-between px-6 z-50 sm:px-16 bg-background-90 border-b border-border backdrop-blur-lg">
      <a
        href="/"
        className="text-xl text-text font-space tracking-wide hover:text-info"
      >
        Vigilant
      </a>

      <div className="hidden sm:flex gap-8 text-text font-light text-body items-center">
        <a href={`https://docs.vigilant.run`} className="hover:text-info">
          Docs
        </a>
        <a href={`/changelog`} className="hover:text-error">
          Changelog
        </a>
        <a href={`/pricing`} className="hover:text-success">
          Pricing
        </a>
        <a
          href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}
          className="hover:text-warning"
        >
          Login
        </a>
        <a
          href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}
          className="px-4 py-2 bg-warning-tint border border-warning-tint text-warning hover:border-warning"
        >
          Sign up
        </a>
      </div>

      <button
        className="sm:hidden text-text"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <LuX size={24} /> : <LuMenu size={24} />}
      </button>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="absolute top-full left-0 right-0 bg-background2 border-b border-border flex flex-col h-[calc(100vh-100%)] sm:hidden">
          <a
            href={`https://docs.vigilant.run`}
            className="text-text-1 hover:text-info px-6 py-4 w-full"
          >
            Docs
          </a>
          <a
            href={`/changelog`}
            className="text-text-1 hover:text-error px-6 py-4 w-full"
          >
            Changelog
          </a>
          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}
            className="text-text-1 hover:text-warning px-6 py-4 w-full"
          >
            Login
          </a>
          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}
            className="text-text-1 hover:text-success px-6 py-4 w-full"
          >
            Sign up
          </a>
        </div>
      )}
    </nav>
  )
}

export default Navbar
