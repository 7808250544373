import { useEffect, useState } from 'react'
import { ArcadeButton } from '../../../../library/Button/Buttons'

const HeadlineCard: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === ' ') {
        e.preventDefault()
        window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/signup`
      }
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('resize', checkMobile)
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <div className="relative z-20 max-w-[550px] p-6 sm:p-8 bg-background2 border border-border">
      <h1 className="mb-6 text-4xl sm:text-5xl sm:leading-snug tracking-wide font-nippo font-extralight">
        The fastest way to debug your app
      </h1>
      <p className="max-w-[500px] mb-10 text-lg font-light text-text2">
        Search across all your logs instantly, get alerts when things break, and
        fix issues before users complain.
      </p>
      <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}>
        <ArcadeButton>
          {isMobile ? 'TAP TO START' : 'PRESS SPACE TO START'}
        </ArcadeButton>
      </a>
    </div>
  )
}

export default HeadlineCard
