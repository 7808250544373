import { CodeBlock } from './CodeBlock'

export default function CodeCard() {
  return (
    <div className="relative w-full max-w-[800px] bg-background2 border border-border">
      <div className="p-8">
        <h2 className="mb-3 text-2xl font-nippo font-extralight">
          Fast Integration
        </h2>
        <p className="max-w-[600px] text-base text-text2 font-space font-light">
          Start sending logs with a single code snippet. It works with existing
          log statements, no changes needed.
        </p>
      </div>
      <div className="w-full h-[400px] px-4 sm:px-8 pb-4 sm:pb-8">
        <CodeBlock snippets={snippets} />
      </div>
    </div>
  )
}

const snippets = [
  {
    title: 'Node.js',
    language: 'javascript',
    content: `import { AutocaptureLogger } from "vigilant-js";

const logger = new AutocaptureLogger({
  name: "service-name",
  url: "log.vigilant.run:4317",
  token: "tk_1234567890", // PUT YOUR API KEY HERE
  passthrough: true,
});

logger.enable();

console.log("User logged in");
console.error("Database connection failed");
`,
  },
  {
    title: 'Python',
    language: 'python',
    content: `from vigilant import create_autocapture_logger

logger = create_autocapture_logger(
    name="service-name",
    url="log.vigilant.run:4317",
    token="tk_1234567890",  # PUT YOUR API KEY HERE
    passthrough=True,
)

logger.enable()

print("User logged in")
logger.info("Database connection failed")`,
  },
  {
    title: 'Go',
    language: 'go',
    content: `import (
    "context"
    "github.com/vigilant-run/vigilant-golang"
)

loggerOptions := vigilant.NewLoggerOptions(
    vigilant.WithLoggerURL("log.vigilant.run:4317"),
    vigilant.WithLoggerToken("tk_1234567890"),  // PUT YOUR API KEY HERE
    vigilant.WithLoggerName("service-name"),
)

logger := vigilant.NewLogger(loggerOptions)
defer logger.Shutdown(context.Background())

logger.Info(context.Background(), "User logged in")
`,
  },
]
