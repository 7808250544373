import { LuMail, LuShieldAlert, LuSlack } from 'react-icons/lu'
import { useState, useEffect, useRef, useCallback } from 'react'

export default function AlertCard() {
  const [pulses, setPulses] = useState<number[]>([])
  const pulseIdRef = useRef(0)

  const createPulse = useCallback(() => {
    const newPulseId = pulseIdRef.current
    pulseIdRef.current += 1
    setPulses((prev) => [...prev, newPulseId])
  }, [])

  const deletePulse = (pulseId: number) => {
    setPulses((prev) => prev.filter((id) => id !== pulseId))
  }

  return (
    <div className="relative w-full bg-background2 border border-border overflow-hidden">
      <div className="p-8">
        <h2 className="mb-3 text-2xl font-nippo font-extralight select-none">
          Custom Alerts
        </h2>
        <p className="max-w-[600px] text-base text-text2 font-space font-light select-none">
          Turn log messages and errors into instant alerts.
        </p>
      </div>
      <div className="relative w-full h-[288px]">
        <div className="z-10 absolute bottom-[32px] left-[32px] p-6 rounded-full bg-background2">
          <LuMail className="w-6 h-6 text-info" />
          <div className="absolute top-0 right-0 w-full h-full bg-info-tint border border-info-tint rounded-full" />
        </div>
        <div className="z-10 absolute top-[32px] right-[32px] p-6 rounded-full bg-background2">
          <LuSlack className="w-6 h-6 text-info" />
          <div className="absolute top-0 right-0 w-full h-full bg-info-tint border border-info-tint rounded-full" />
        </div>
        <div
          onClick={createPulse}
          className="z-10 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-8 rounded-full bg-background2"
        >
          <LuShieldAlert className="w-8 h-8 text-error" />
          <div className="absolute top-0 right-0 w-full h-full bg-error-tint border border-error-tint hover:border-error cursor-pointer rounded-full" />
        </div>
        {pulses.map((pulseId) => (
          <Pulse key={pulseId} deletePulse={() => deletePulse(pulseId)} />
        ))}
        <AlertFeed createAlert={createPulse} side="left" />
        <AlertFeed createAlert={createPulse} side="right" />
      </div>
    </div>
  )
}

interface PulseProps {
  deletePulse: () => void
}

const Pulse = ({ deletePulse }: PulseProps) => {
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsAnimating(true)
    })

    setTimeout(() => {
      deletePulse()
    }, 2000)
  }, [deletePulse])

  return (
    <div
      className="absolute top-[50%] left-[50%] rounded-full border border-error pointer-events-none"
      style={{
        width: '96px',
        height: '96px',
        transition: 'all 2s ease-out',
        opacity: isAnimating ? 0 : 0.8,
        transform: `translate(-50%, -50%) scale(${isAnimating ? 4 : 1})`,
      }}
    />
  )
}

interface AlertFeedProps {
  side: 'left' | 'right'
  createAlert: () => void
}

const AlertFeed = ({ side, createAlert }: AlertFeedProps) => {
  const [dots, setDots] = useState<{ id: number; isError: boolean }[]>([])
  const dotIndexRef = useRef(0)

  const deleteDot = (dotId: number) => {
    setDots((prev) => prev.filter((dot) => dot.id !== dotId))
  }

  const createDot = useCallback(() => {
    const rightOffset = side === 'left' ? 0 : 6
    const isError = (dotIndexRef.current + rightOffset) % 12 === 0
    const newDot = {
      id: dotIndexRef.current,
      isError,
    }
    dotIndexRef.current += 1
    setDots((prev) => [...prev, newDot])
    setTimeout(() => {
      if (isError) createAlert()

      deleteDot(newDot.id)
    }, 5000)
  }, [createAlert, deleteDot])

  useEffect(() => {
    const interval = setInterval(() => {
      createDot()
    }, 600)

    return () => clearInterval(interval)
  }, [createAlert])

  return (
    <div
      className={`w-[calc(50%-48px)] h-2 absolute top-[50%] ${
        side === 'left' ? 'left-0' : 'right-0 -rotate-180'
      } translate-y-[-50%]`}
    >
      {dots.map((dot) => (
        <Dot key={dot.id} isError={dot.isError} />
      ))}
    </div>
  )
}

const Dot = ({ isError }: { isError: boolean }) => {
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsAnimating(true)
    })
  }, [])

  return (
    <div
      className={`absolute top-0 w-2 h-2 bg-error rounded-full ${
        isError ? 'bg-error' : 'bg-info/50'
      }`}
      style={{
        left: `${isAnimating ? 100 : -5}%`,
        transition: 'all 5s linear',
      }}
    />
  )
}
