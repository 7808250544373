import { useState, useEffect } from 'react'

const DOT_COUNT = 15

interface DividerDotsProps {
  color: 'error' | 'info' | 'success' | 'warning'
}

export default function DividerDots({ color }: DividerDotsProps) {
  const [activeDotIndex, setActiveDotIndex] = useState(0)

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const dotsContainer = document.querySelector('.divider-dots')
      if (!dotsContainer) return

      const dots = Array.from(dotsContainer.children)

      const nearestDot = dots.reduce(
        (nearest, dot, index) => {
          const dotRect = dot.getBoundingClientRect()
          const dotCenterX = dotRect.left + dotRect.width / 2
          const distance = Math.abs(event.clientX - dotCenterX)

          if (distance < nearest.distance) {
            return { index, distance }
          }
          return nearest
        },
        { index: -1, distance: Infinity }
      ).index

      setActiveDotIndex(nearestDot)
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  return (
    <div className="w-full py-4 flex justify-center items-center gap-8 divider-dots">
      {Array.from({ length: DOT_COUNT }).map((_, index) => (
        <DividerDot
          key={index}
          color={index === activeDotIndex ? color : 'default'}
        />
      ))}
    </div>
  )
}

interface DividerDotProps {
  color: 'default' | 'error' | 'info' | 'success' | 'warning'
}

function DividerDot({ color }: DividerDotProps) {
  const styles = {
    default: 'w-[3px] h-[3px] m-[1.5px] bg-text',
    error: 'w-[8px] h-[8px] bg-error',
    info: 'w-[8px] h-[8px] bg-info',
    success: 'w-[8px] h-[8px] bg-success',
    warning: 'w-[8px] h-[8px] bg-warning',
  }
  return <div className={`${styles[color]} rounded-full flex-shrink-0`}></div>
}
