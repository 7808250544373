import { useState, useEffect } from 'react'

const CELL_SIZE = 32
const X_OFFSET = 6
const Y_OFFSET = 4
const MOBILE_X_OFFSET = 2
const MOBILE_Y_OFFSET = 2

export default function CallToActionSection() {
  const [dimensions, setDimensions] = useState({
    columns: 0,
    rows: 0,
  })
  const [greenSquare, setGreenSquare] = useState({ x: X_OFFSET, y: Y_OFFSET })
  const [redSquare, setRedSquare] = useState({ x: -X_OFFSET, y: Y_OFFSET })
  const [yellowSquare, setYellowSquare] = useState({
    x: X_OFFSET,
    y: -Y_OFFSET,
  })
  const [blueSquare, setBlueSquare] = useState({
    x: -X_OFFSET,
    y: -Y_OFFSET,
  })

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  useEffect(() => {
    const updateDimensions = () => {
      const container = document.querySelector('[data-cta-container]')
      if (!container) return

      const columns = Math.ceil(container.clientWidth / CELL_SIZE)
      const rows = Math.ceil(container.clientHeight / CELL_SIZE)

      setDimensions({ columns, rows })

      if (window.innerWidth > 768) {
        setYellowSquare({ x: X_OFFSET, y: Y_OFFSET })
        setRedSquare({ x: columns - X_OFFSET - 1, y: Y_OFFSET })
        setGreenSquare({ x: X_OFFSET, y: rows - Y_OFFSET - 1 })
        setBlueSquare({ x: columns - X_OFFSET - 1, y: rows - Y_OFFSET - 1 })
      } else {
        setYellowSquare({ x: MOBILE_X_OFFSET, y: MOBILE_Y_OFFSET })
        setRedSquare({ x: columns - MOBILE_X_OFFSET - 1, y: MOBILE_Y_OFFSET })
        setGreenSquare({ x: MOBILE_X_OFFSET, y: rows - MOBILE_Y_OFFSET - 1 })
        setBlueSquare({
          x: columns - MOBILE_X_OFFSET - 1,
          y: rows - MOBILE_Y_OFFSET - 1,
        })
      }
    }
    updateDimensions()

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const getStyles = (square: { x: number; y: number }) => {
    if (square.x === redSquare.x && square.y === redSquare.y) {
      return 'border-error bg-error-tint cursor-not-allowed'
    }
    if (square.x === yellowSquare.x && square.y === yellowSquare.y) {
      return 'border-warning bg-warning-tint cursor-pointer'
    }
    if (square.x === greenSquare.x && square.y === greenSquare.y) {
      return 'border-success bg-success-tint cursor-not-allowed'
    }
    if (square.x === blueSquare.x && square.y === blueSquare.y) {
      return 'border-info bg-info-tint cursor-not-allowed'
    }
    return 'border-border/50 bg-background'
  }

  const moveSquareAwayFrom = (hoverX: number, hoverY: number) => {
    const moveSquare = (square: { x: number; y: number }) => {
      const dx = square.x - hoverX
      const dy = square.y - hoverY

      if (Math.abs(dx) <= 1 && Math.abs(dy) <= 1) {
        const newX = Math.min(
          Math.max(0, square.x + Math.sign(dx)),
          dimensions.columns - 1
        )
        const newY = Math.min(
          Math.max(0, square.y + Math.sign(dy)),
          dimensions.rows - 1
        )
        return { x: newX, y: newY }
      }
      return square
    }

    setRedSquare(moveSquare(redSquare))
    setGreenSquare(moveSquare(greenSquare))
    setBlueSquare(moveSquare(blueSquare))
    setYellowSquare(moveSquare(yellowSquare))
  }

  return (
    <div
      data-cta-container
      className="relative w-full h-[480px] grid overflow-hidden"
      style={{
        gridTemplateColumns: `repeat(${dimensions.columns}, ${CELL_SIZE}px)`,
        gridTemplateRows: `repeat(${dimensions.rows}, ${CELL_SIZE}px)`,
      }}
    >
      <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] p-4 flex justify-center items-center bg-popup-bg border border-popup-border">
        <h3 className="text-base sm:text-lg font-space font-light text-center">
          {isMobile ? 'Tab' : 'Click'} the{' '}
          <span className="text-warning">yellow square</span> to get started.
        </h3>
      </div>
      {Array.from({ length: dimensions.columns * dimensions.rows }).map(
        (_, i) => {
          const x = i % dimensions.columns
          const y = Math.floor(i / dimensions.columns)
          const isYellowSquare = x === yellowSquare.x && y === yellowSquare.y

          return isYellowSquare ? (
            <a
              key={`${x},${y}`}
              href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}
              className={`border box-border ${getStyles({ x, y })}`}
              style={{
                width: CELL_SIZE,
                height: CELL_SIZE,
                borderWidth: '0.5px',
              }}
              onMouseEnter={() => moveSquareAwayFrom(x, y)}
            />
          ) : (
            <div
              key={`${x},${y}`}
              className={`border box-border ${getStyles({ x, y })}`}
              style={{
                width: CELL_SIZE,
                height: CELL_SIZE,
                borderWidth: '0.5px',
              }}
              onMouseEnter={() => moveSquareAwayFrom(x, y)}
            />
          )
        }
      )}
    </div>
  )
}
