import { FaDiscord, FaLinkedin, FaGithub, FaYCombinator } from 'react-icons/fa'

export default function Footer() {
  return (
    <nav className="w-full flex flex-col lg:flex-row justify-between px-8 lg:px-16 pt-16 pb-24 bg-background border-t border-border gap-12">
      <div className="flex flex-col justify-between gap-4">
        <a
          href="/"
          className="text-xl text-text font-space tracking-wide hover:text-info"
        >
          Vigilant
        </a>
        <div className="flex flex-col gap-2 text-sm font-light text-text2">
          <span className="flex items-center gap-2">
            <span>Backed by</span>
            <div className="flex items-center gap-1">
              <FaYCombinator size={16} className="text-warning" />
              <span className="text-warning">Combinator</span>
            </div>
          </span>
          <span>© 2023 AutoEmber, Inc.</span>
        </div>
      </div>

      <div className="flex flex-wrap md:flex-nowrap gap-12 text-text font-light">
        <div className="flex flex-col gap-4 w-full md:w-auto">
          <span className="font-light text-info cursor-default">Docs</span>
          <div className="flex flex-col gap-4">
            <a
              href={`${process.env.REACT_APP_DOCS_URL}/overview`}
              className="text-text2 hover:text-info"
            >
              Overview
            </a>
            <a
              href={`${process.env.REACT_APP_DOCS_URL}/quickStart/nodejs`}
              className="text-text2 hover:text-info"
            >
              Node.js
            </a>
            <a
              href={`${process.env.REACT_APP_DOCS_URL}/quickStart/python`}
              className="text-text2 hover:text-info"
            >
              Python
            </a>
            <a
              href={`${process.env.REACT_APP_DOCS_URL}/quickStart/go`}
              className="text-text2 hover:text-info"
            >
              Go
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full md:w-auto">
          <span className="font-light text-success cursor-default">Social</span>
          <div className="flex flex-col gap-4">
            <a
              href={process.env.REACT_APP_GITHUB_URL}
              className="text-text2 hover:text-success flex items-center gap-2"
            >
              <FaGithub size={16} />
              GitHub
            </a>
            <a
              href={process.env.REACT_APP_DISCORD_URL}
              className="text-text2 hover:text-success flex items-center gap-2"
            >
              <FaDiscord size={16} />
              Discord
            </a>
            <a
              href={process.env.REACT_APP_LINKEDIN_URL}
              className="text-text2 hover:text-success flex items-center gap-2"
            >
              <FaLinkedin size={16} />
              LinkedIn
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full md:w-auto">
          <span className="font-light text-error cursor-default">Legal</span>
          <div className="flex flex-col gap-4">
            <a href="/terms" className="text-text2 hover:text-error">
              Terms of Service
            </a>
            <a href="/privacy" className="text-text2 hover:text-error">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}
